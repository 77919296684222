<template>
    <div style="width: 100%; height: 100%; padding: 20px; box-sizing: border-box">
        <div class="score-container pdf-screen" ref="scoreContent" id="print">
            <img src="../../assets/transcript_bg.jpg" crossorigin="anonymous" class="transcript-bg"
                style="z-index: -1;" />
            <div style="width: 100%;padding: 0px 30px;box-sizing: border-box;">
                <div style="display: flex;align-items: center;margin-top: 60px;">
                    <div style="flex: 1;width: 100%;padding-left: 15px;text-align: right;">
                        <p class="project_code">{{ showProjectName }} World Student Publication Review </p>
                        <p class="content-label" style="margin-top: 5px;font-size: 28px;">Transcript of Entry</p>
                    </div>
                </div>
                <p class="score-date">Date: {{ score_date }}</p>
                <div class="empty-line"></div>
                <p class="content-label" style="margin-top: 10px;">Entry Information</p>
                <div class="empty-line"></div>
                <div class="entry-content">
                    <div style="display: flex;margin-top: 15px;">
                        <div style="flex: 4;display: flex;">
                            <p style="width: 100px;text-align: right;font-weight: bold;">Entry Title:&nbsp;</p>
                            <p>{{ entryInfo.title }}</p>
                        </div>
                        <div style="flex: 3.5;display: flex;">
                            <p style="width: 150px;text-align: right;font-weight: bold;"> Student:&nbsp;</p>
                            <p> {{ studentName }}</p>
                        </div>
                    </div>
                    <div style="display: flex;margin-top: 20px;">
                        <div style="flex: 4;display: flex;">
                            <p style="width: 100px;text-align: right;font-weight: bold;">Category:&nbsp;</p>
                            <p>{{ entryInfo.category  }} {{ entryInfo.subcategory ? ' - ' + entryInfo.subcategory:'' }}</p>
                        </div>
                        <div style="flex: 3.5;display: flex;">
                            <p style="width: 150px;text-align: right;font-weight: bold;"> School:&nbsp;</p>
                            <p style="line-height: 24px;"> {{ entryInfo.account.account_nameEN }}</p>
                        </div>
                    </div>
                    <div style="display: flex;margin-top: 20px;">
                        <div style="flex: 4;display: flex;">
                            <p style="width: 100px;text-align: right;font-weight: bold;">Type:&nbsp;</p>
                            <p> {{ entryType }}</p>
                        </div>
                        <div style="flex: 3.5;display: flex;">
                            <p style="width: 150px;text-align: right;font-weight: bold;"> Application ID:&nbsp;</p>
                            <p style="line-height: 24px;"> {{ application_id }}</p>
                        </div>
                    </div>
                </div>

                <div class="empty-line" style="margin-top: 20px;"></div>
                <p class="content-label" style="margin-top: 10px;">Score</p>
                <div class="empty-line"></div>
                <div style="display: flex;height: 450px;">
                    <div id="echart-radar" class="echart_size"></div>
                    <div style="flex: 1;padding: 40px 0px 20px 0px;width: 100%;box-sizing: border-box;font-size: 14px;">
                        <div style="display: flex;">
                            <p class="table-normal-header" style="flex: 4;font-weight: bold;">Criteria</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal-header" style="flex: 1;font-weight: bold;"> Max.</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal-header" style="flex: 1;font-weight: bold;">Score</p>
                        </div>
                        <div v-for="(dimension, index)  in dimensions" :key="index"
                            style="display: flex;margin-top: 4px;">
                            <p class="table-normal" style="flex: 4;">{{ dimension.name }}</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal" style="flex: 1;">{{ dimension.value }}</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal" style="flex: 1;">{{ dimension.real_score }}</p>
                        </div>
                        <div style="display: flex;margin-top: 4px;">
                            <p class="table-normal" style="flex: 5;">Total Score</p>
                            <div style="width: 4px;"></div>
                            <p class="table-normal" style="flex: 1;">{{ totalScore }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-button type="primary" size="small" style="margin-bottom: 20px; position: absolute" @click="exportPDF">
            Download</el-button>
    </div>
</template>

<script>
import { getEntryMemberEntry } from '../../api/index'
import "../../assets/common/font.css";
import * as echarts from "echarts"

export default {
    name: "index",
    data() {
        return {
            entry_id: this.$route.query.id,
            entryInfo: {},
            totalScore: 0,
            project_code: '',
            score_date: '',
            dimensions: [],
            showProjectName: '',
            entryType: '',
            studentName: '',
             application_id:''
        }
    },
    mounted() {
        // if (true) {
        //     this.entry_id = '64a187afa4640bd5028b4569'
        // }
        this.fetchData()
    },
    methods: {
        fetchData() {
            getEntryMemberEntry(this.entry_id).then((res) => {
                let result = res.data.data
                this.entryInfo = result
                this.application_id = result.application._id.$id

                if (result.student.student_lastName_pinyin) {
                    this.studentName =
                        result.student.student_givenName_pinyin +
                        " " +
                        result.student.student_lastName_pinyin;
                } else {
                    this.studentName =
                        result.student.student_lastName +
                        " " +
                        result.student.student_givenName;
                }

                this.project_code = result.project_code

                let entryScore = 0
                let bonus_points = 0;
                bonus_points = result.eae_entry.score.bonus_points
                let scoring_dimension = result.eae_entry.score.scoring_dimension
                let keys = Object.keys(scoring_dimension)
                for (let i = 0; i < keys.length; i++) {
                    let score = scoring_dimension[keys[i]]
                    entryScore = entryScore + parseInt(score)
                }
                this.totalScore = (entryScore + bonus_points)

                let cert_date = ''
                let entry_date = result.project_manage.entry_date
                for (let i = 0; i < entry_date.length; i++) {
                    let item = entry_date[i]
                    if (item.app_type == 'indie' && result.entry_type == 'individual') {
                        cert_date = item.cert_date
                        break
                    }
                    if (item.app_type == 'team' && result.entry_type == 'team') {
                        cert_date = item.cert_date
                        break
                    }
                }
                this.formatCertDate(cert_date)

                if (result.entry_type == 'individual') {
                    this.entryType = 'Individual'
                } else {
                    this.entryType = 'Team'
                }
                this.$nextTick(() => {
                    this.initRadar()
                })
            })
        },

        initRadar() {

            let id = document.getElementById('echart-radar')
            let myCharts = echarts.init(id)

            let datas = []
            this.dimensions = this.entryInfo.eae_entry.score.dimensions
            this.dimensions.forEach((element) => {
                datas.push({
                    name: element.name,
                    score: element.real_score,
                    totalScore: element.value
                })
            })
            let colorList = ['#D64743', '#D64743'];
            let scoreList = datas.map((n) => { return n.score; });
            let nameList = [];
            datas.forEach((item) => {
                nameList.push({
                    name: item.name,
                    max: item.totalScore,
                    score: item.score
                });
            });
            let option = {
                radar: {
                    center: ['45%', '42%'], // 图表位置
                    radius: '60%', // 图表大小
                    // 设置雷达图中间射线的颜色
                    axisLine: {
                        lineStyle: {
                            color: '#333',
                            fontSize: 30
                        }
                    },
                    indicator: nameList,
                    // 雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
                    splitArea: {
                        areaStyle: {
                            color: '#fff' // 图表背景的颜色
                        }
                    },
                    name: {
                        lineHeight: 18,
                        formatter: (labelName, raw) => {
                            return (
                                labelName
                            );
                        },
                        rich: {
                            score: {
                                color: colorList[0],
                                fontSize: 16
                            }
                        }
                    }
                },
                series: [
                    {
                        type: 'radar',
                        data: [
                            {
                                value: scoreList,
                                // name: '你的得分',
                                itemStyle: {
                                    color: colorList[0]
                                },
                                // label: {
                                //     show: false,
                                //     fontSize: 16,
                                //     position: 'right',
                                //     color: colorList[0],
                                //     formatter: function (params) {
                                //         return params.value;
                                //     }
                                // },
                                // areaStyle: {
                                //     color: colorList[0],
                                //     opacity: 0.2
                                // }
                            },

                        ]
                    }
                ]
            };
            myCharts.setOption(option)
            window.addEventListener('resize', function () {
                myCharts.resize()
            })
        },
        exportPDF() {
            this.$pdf(this.$refs.scoreContent, {
                name: new Date().getTime(),
                scale: 2,
                ignoreElements: (element) => {
                    if (element.className.indexOf("el-button") !== -1) {
                        return true;
                    }
                },
            });
        },

        formatCertDate(publish_time) {
            let publishTime = publish_time;
            var date = new Date(publishTime);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            this.score_date = this.formatMonthToEng(month) + " " + day + ", " + year;
        },

        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },
    }
}
</script>
<style scoped lang="less">
.table-normal-header {
    color: white;
    background-color: #D64743;
    opacity: 0.8;
    text-align: center;
    padding: 3px 0px;
    box-sizing: border-box;
}

.table-normal {
    border: 1px solid #D64743;
    background-color: white;
    color: #D64743;
    opacity: 0.8;
    text-align: center;
    padding: 3px 0px;
    box-sizing: border-box;
}

.transcript-bg {
    position: absolute;
    width: 267mm;
    height: auto;
    object-fit: cover;
}

.score-container {
    position: absolute;
    -webkit-text-size-adjust: 100% !important;
    font-variant: normal;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 267mm;
    height: 380mm;

    .project_code {
        color: #333333;
        font-size: 28px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .content-label {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }

    .score-date {
        text-align: right;
        margin-top: 10px;
        color: #333333;
        font-size: 18px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .empty-line {
        width: 100%;
        height: 2px;
        background-color: #333333;
        margin-top: 15px;
    }

    .entry-content {
        margin-top: 10px;
        color: #333333;
        font-size: 18px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .echart_size {
        width: 600px;
        height: 100%;
    }

}
</style>